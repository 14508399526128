import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { NativeSelect } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import Solana from '../../../Static/Image/solana.svg'
import EthereumImg from '../../../Static/Image/ethereum.svg'
import AvalancheImg from '../../../Static/Image/avalanche.svg'
import FantomImg from '../../../Static/Image/fantom.svg'
import HarmonyImg from '../../../Static/Image/harmony.svg'
import PolygomImg from '../../../Static/Image/polygon.svg'
import Tezos from '../../../Static/Image/tezos.svg'
const useStyles = makeStyles((theme) => ({
    formControl: {
        marginLeft: "20px",
        minWidth: 130,
        display: "flex",
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },

        "& .MuiSvgIcon-root": {
            color: "#94A1B7",
            marginTop: "-1px"
        },
        "& .MuiSelect-root": {
            color: "#94A1B7",
            backgroundColor: "#263143",
            borderRadius: "5px",
            height: "22px",
            padding: "10px",

        },
        "& .MuiInput-root": {
            "&:after": {
                border: "none",
                borderBottom: "none"
            },
        },
    },
    Text: {
        fontSize: "14px",
        // marginLeft: "12px",
        color: "rgb(38,49,67)",
        fontWeight: "400",
        marginTop: "5px",
        fontFamily: "Sofia Pro",


    },
    TextList: {
        fontSize: "14px",
        //marginLeft: "25px",
        color: "#94A1B7",

        fontWeight: "400",
        marginTop: "5px",
        fontFamily: "Sofia Pro",

    }
}));

export default function Suggest() {
    const classes = useStyles();
    const [age, setAge] = React.useState("10");
    const [open, setOpen] = React.useState(false);

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <div>
            <FormControl className={classes.formControl}>
                <Select

                    labelId="select-label"
                    id="select"
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    value={age}
                    onChange={handleChange}
                    defaultValue={age}
                >

                    <MenuItem value={10} style={{ alignItems: "center" }}>
                        <img style={{
                            width: "22px",
                            fontFamily: "Sofia Pro",
                            height: "22px", marginRight: "5px"
                        }} src={EthereumImg} />Ethereum</MenuItem>
                    <MenuItem value={"Ethereum"} >
                        <img style={{
                            width: "22px",
                            fontFamily: "Sofia Pro",
                            height: "22px", marginRight: "5px"
                        }} src={Solana} />Solana</MenuItem>

                    <MenuItem className={classes.Text} value="comingSoon" disabled>-Coming soon-</MenuItem>
                    <MenuItem className={classes.TextList} value="ethereum" disabled>
                        <img style={{ width: "22px", marginRight: "5px" }} src={EthereumImg} />Ethereum</MenuItem>
                    <MenuItem className={classes.TextList} value="Polygon" disabled>
                        <img style={{ width: "22px", marginRight: "5px" }} src={PolygomImg} />Polygon</MenuItem>
                    <MenuItem className={classes.TextList} value="Bsc" disabled>
                        <img style={{ width: "22px", marginRight: "5px" }} src={EthereumImg} />BSc</MenuItem>
                    <MenuItem className={classes.TextList} value="Fantom" disabled>
                        <img style={{ width: "22px", marginRight: "5px" }} src={FantomImg} />Fantom</MenuItem>
                    <MenuItem className={classes.TextList} value="Avalanche" disabled>
                        <img style={{ width: "22px", marginRight: "5px" }} src={AvalancheImg} />Avalanche</MenuItem>
                    <MenuItem className={classes.TextList} value="solana" disabled>
                        <img src={Solana} style={{ width: "22px", marginRight: "5px" }} />Solana</MenuItem>
                    <MenuItem className={classes.TextList} value="Tezos" disabled>
                        <img style={{ width: "22px", marginRight: "5px" }} src={Tezos} />Tezos</MenuItem>
                    <MenuItem className={classes.TextList} value="Harmony" disabled>
                        <img style={{ width: "22px", marginRight: "5px" }} src={HarmonyImg} />Harmony</MenuItem>

                </Select>
            </FormControl>
        </div>
    );
}