
// Filters Reducer

export const filtersInitialState = {
    text: '',

};
export const FilterReducer = (state = filtersInitialState, action) => {
    switch (action.type) {
        case 'SET_TEXT':
            return {
                text: action.text
            };
        default: return state

    }
}