export const ProfileInitialState = false

export const ProfileReducer = (state = ProfileInitialState, action) => {
    switch (action.type) {
        case 'INITIAL-PROFILE':
            return {
                state: false
            };
        default: return state

    }
}