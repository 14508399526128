import { combineReducers } from "redux";
import { assetsInitialState, AssetsReducer } from "./assets";
import { AuthInitialState, AuthReducer } from "./auth";
import { FanInitialState, FanReducer } from "./fan";
import { FilterReducer, filtersInitialState } from "./filter";
import { ProfileInitialState, ProfileReducer } from "./profile";
import { projectInitialState, ProjectReducer } from "./project";
import { TipInitialState, TipReducer } from "./tips";
import { TokenInitialState, TokenReducer } from "./tokens";

export const InitalState = {
    filter: filtersInitialState,
    Profile: ProfileInitialState,
    project: projectInitialState,
    assets: assetsInitialState,
    token: TokenInitialState,
    tip: TipInitialState,
    fan: FanInitialState,
    Auth:AuthInitialState
}

export const rootReducer = combineReducers({
    filter: FilterReducer,
    Profile: ProfileReducer,
    project: ProjectReducer,
    assets: AssetsReducer,
     Auth:AuthReducer,
    token: TokenReducer,
    tip: TipReducer,
    fan: FanReducer
})