import styled from "styled-components";
import { NavLink } from 'react-router-dom';


export const HeaderContainer1 = styled.div` 
height:auto; 
width:100% ;
display:flex ;
justify-content:center ;
background-color: rgb(25,31,40);
border-bottom:none ;
`
export const InnerContainer = styled.div`
height:70px ;
width:89%;
display:flex ;
//justify-content:center ;
align-items:center ;
background-color:rgb(25,31,40) ;
@media only screen and (max-width: ${props => props.theme.bpxxl}) {
  max-width:87% ;
  
}
@media only screen and (max-width: ${props => props.theme.bplg}) {
  max-width:90% ;
  
}
@media only screen and (max-width: ${props => props.theme.bpmd}) {
  max-width:100% ;
  
}
@media only screen and (max-width: ${props => props.theme.bpsm}) {
  display:none ;
  
}
`
export const HeaderImage = styled.img` 
//margin-bottom: 8px;
  width: 12%;
  margin-right: 0.5rem;
  margin-top:5px ;
height:32.6px ;
cursor: pointer;
@media only screen and (max-width: ${props => props.theme.bplg}) {
  margin-right:0.3rem ;
  
}
@media only screen and (max-width: ${props => props.theme.bpmd}) {
  margin-right:10px ;
  
}
`
export const ButtonHeader = styled.button` 
width:6% ;
height:42.6px ;
border-radius: 12px;
padding-left: 10px;
padding-right: 10px;
margin-left: 20px;
background-color: #263143;
border:none ;
color:rgb(149,160,172);
@media only screen and (max-width: ${props => props.theme.bpmd}) {
  width:55% ;
  margin-left:10px ;
  
}
/* @media only screen and (max-width: ${props => props.theme.bplg}) {
  max-width:149px ;
  padding-left:10px ;
  
} */
`
export const ButtonImage = styled.img` 
  vertical-align: middle;
  box-sizing:border-box ;
  width:24px ;
  height:24px ;
`
export const ButtonDropDown = styled.img` 
color:#94A1B7;
margin-left:10px ;
width:8.6px ;
height:8.6px ;
vertical-align: middle;

`
export const SearchBox = styled.input` 
width:16% ;
height:44px ;
box-sizing: border-box;
margin-left:25px ;
background-color: #263143;
border-radius: 12px;
border:none;
padding-left:15px ;
margin-right:45px ;
padding-top:7px ;
@media only screen and (max-width: ${props => props.theme.bpmd}) {
  padding-left:0px ;
  max-width:14% ;
  margin-left:2px ;
  margin-right:0px ;
  
}
/* @media only screen and (max-width: ${props => props.theme.bpsm}) {
  padding-left:0px ;
  width:40% ;
  
} */
`
// export const NavLink = styled.button`
// color: #94A1B7;
// margin-left:14rem ;
// margin-right:0.5rem ;
// background-color: rgb(25,31,40);
// border:none;
// @media only screen and (max-width: ${props => props.theme.bpxlg}) {
//   margin-left:4rem ;

// }
// @media only screen and (max-width: ${props => props.theme.bplg}) {
//   margin-left:2rem ;
//   margin-right:0rem ;

// }
// @media only screen and (max-width: ${props => props.theme.bpmd}) {
//   margin-left:0.3rem ;
//   margin-right:0rem ;

// }
/* height:40px ;
width:5% ; */
//`
export const ButtonText = styled.div` 
font-size:16px ;
font-family:Sofia Pro ;
width:max-content ;

`
export const NavButton = styled.button` 
color: #94A1B7;
background-color: rgb(25,31,40);
border:none;
margin-right:0.5rem ;
@media only screen and (max-width: ${props => props.theme.bpmd}) {
  //margin-left:0.3rem ;
  margin-right:3px ;
}
/* height:40px ;
width:5% ; */
`
export const TwitterImage = styled.img` 
  color: #94A1B7;
  padding-right: 0.5rem;
  padding-left: 1.5rem;
  width:24px ;
  height:25px;
`
export const InnerContainerMobile = styled.div` 
width:75%;
height:101px ;
display:none ;
//flex-direction:column ;
align-items:center ;
background-color:rgb(25,31,40) ;
justify-content:space-between ;
padding-right:10% ;
padding-left:2% ;
@media only screen and (max-width: ${props => props.theme.bpsm}) {
  display:flex;
  
}
`
export const MobileContainerTop = styled.div` 
display:none ;
justify-content:center ;
//align-items:center ;
width:100% ;
height:101px ;
background-color:rgb(25,31,40) ;


`
export const MobileHeaderImage = styled.img` 
  width: 130px;
  
height:78.4px ;
cursor: pointer;
`
export const BirdIcon = styled.img` 
width:22px ;
height:18px ;
`
export const HeaderButton = styled.button` 
width:78% ;
height:45px ;
border-radius: 12px;
padding-left: 10px;
padding-right: 10px;
//margin-left: 30px;
background-color: #263143;
border:none ;
display:flex ;
align-items:center ;
justify-content:space-around ;
`
export const HeaderImageButton = styled.img` 
height:24px ;
height:24px ;

`
export const HeaderCombine = styled.div` 
display:flex ;
width:30% ;
align-items:center ;
`
export const HamburgerButton = styled.img` 
width:30px ;
height:30px ;
vertical-align:middle ;
margin-left:10px ;
color:white ;
background-color:rgb(25,31,40) ;
background:none ;
display:none;
@media only screen and (max-width: ${props => props.theme.bpxs}) {
  display:none ;
  
  
}
`



export const HeaderContainer = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  background-color: #191f28;
  padding-left:70px ;
  padding-right:80px ;
  position:fixed ;
  align-items: center;
  justify-content:space-between ;
  box-sizing:border-box ;
  z-index:3;
  @media only screen and (max-width: ${props => props.theme.bpmd}) {
    padding-right:40px ;
  }
  @media only screen and (max-width: ${props => props.theme.bpxs}) {
  padding-top:10px  ;
  padding-left:0px ;
  padding-right:0px ;
  
}
@media only screen and (max-width: ${props => props.theme.bpsm}) {
 padding:10px  ;
}

`;


export const LeftCont = styled.div`
  //width: 50%;
  display: flex;
  align-items: center;
`;

export const Logo = styled.nav`
  display: flex;
  flex-direction: row;
  font-size: 30px;
  font-weight: 800;
  margin-left:2.5% ;
  cursor: pointer;
  @media only screen and (max-width: ${props => props.theme.bpsm}) {
margin-left:1% ;
}
`;

export const Nft = styled.div`
  color: #ffffff;
  font-family: 'Sofia Pro';
`;

export const Feed = styled.div`
  color: #5b6afc;
  font-family: 'Sofia Pro';
`;

export const Chaincont = styled.div`
  background-color: #263143;
  height: 38px;
  width: 130px;
  border-radius: 15%;
  display:flex ;
  align-items:center ;
  margin-left: 20px;
  @media only screen and (max-width: ${props => props.theme.bpxs}) {
 // width:25px;
  margin-left:10px ;
}
`;

export const RightCont = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  box-sizing:border-box ;

`;

export const TabItem = styled.div`
  color: #ffffff;
  font-size: 18px;
  padding-right: 20px;
  @media only screen and (max-width: ${props => props.theme.bpxs}) {
  display:none ;
  
}
`;
export const HeaderRightCont = styled.div` 
display:flex;
//width:50% ;
//padding-left:7% ;
padding-right:10% ;
align-items:center ;
@media only screen and (max-width: ${props => props.theme.bplg}) {
 // width:25px;
}
@media only screen and (max-width: ${props => props.theme.bpmd}) {
 // width:25px;
}
@media only screen and (max-width: ${props => props.theme.bpxs}) {
  
}
`
export const HeaderHomeNav = styled.div` 
position: relative;
 font-size: 17px;
 cursor: pointer;
`
export const ProjectButton = styled(NavLink)` 
border:none;
//margin-right:10px ;
font-size:17px ;
font-family:Sofia Pro;
height:40px ;
border-radius:10px ;
align-items:center ;
min-width:33% ;
display:flex ;
justify-content:center ;
margin-left:10px ;
background-color: #191f28;
color:#94A1B7 ;
cursor: pointer;
  :hover{
    color:rgb(62, 158, 214) ;
    background-color: #263143 ;

  }
  @media only screen and (max-width: ${props => props.theme.bpmd}) {
  min-width:50% ;
  }
@media only screen and (max-width: ${props => props.theme.bpxs}) {
  display:none ;
  
}
`
export const ProjectButton1 = styled(NavLink)` 
border:none;
font-size:17px ;
font-family:Sofia Pro;
border-radius:10px ;

//margin-left:25px ;
align-items:center ;
height:40px ;
//margin-right:5px ;
min-width:23% ;
justify-content:center ;
display:flex ;
background-color: #191f28;

color:#94A1B7 ;
cursor: pointer;
:hover{
    color:rgb(62, 158, 214) ;
    background-color: #263143 ;
  } 
  @media only screen and (max-width: ${props => props.theme.bpmd}) {
  min-width:43% ;
  }
@media only screen and (max-width: ${props => props.theme.bpxs}) {
  display:none ;
  
}
`
export const HeaderBox = styled.div` 
width:130px;
height:40px;
border-radius:20px ;
background-color: #263143 ;
display:flex ;
margin-left:8% ;
align-items:center ;
justify-content:space-evenly ;
cursor: pointer;
@media only screen and (max-width: ${props => props.theme.bpxs}) {
  display:none ;
  
}
@media only screen and (max-width: ${props => props.theme.bpmd}) {
 // width:25px;
 display:none ;
}
`
export const HeadBoxText = styled.div` 
font-size:17px ;
color:navy ;
font-weight:500 ;
//padding-right:10px ;
`
export const HeadBoxText1 = styled.div`
font-size:25px ;
color:navy ;
font-weight:500 ;
margin-top:7px ;
`
export const ConnectButton = styled.button` 
border:none ;
background-color:#263143;
font-family:Sofia pro;
font-size:18px ;
cursor: pointer;
color:#94A1B7;
margin-bottom:2px ;
width:130px ;
height:40px ;
display:flex ;
justify-content:space-around ;
align-items:center ;
border-radius:20px ;
outline:none ;
`
